import React from "react"
import { graphql } from "gatsby"
import Image from "./../components/image"

import PagesLayout from '../components/pagesLayout'

import { useModalContextToggle } from './../components/modalContext'

const HowItWorks = ({ data, content }) => {
    return (
        <div className="how-it-works points uk-width-1-2@s uk-margin-medium-bottom">
            <div className="image-wrapper">
                <Image imgsrc={`${data.icon}`} alt="commissions icon" />
            </div>
            <div className="points-content">
                <h3>{data.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        </div>
    )
}

const LearnMore = ({ data }) => {

    const toggle = useModalContextToggle()

    return (
        <PagesLayout>
            <div className="banner_header">
                <div className="uk-container">
                    <h1>Learn More About Us</h1>
                </div>
            </div>

            <div id="products" className="uk-margin-large-bottom">
                <div className="uk-container uk-grid align-center uk-margin-large-top">
                    <div className="product-content uk-width-2-3@s uk-margin-medium-bottom">
                        <div dangerouslySetInnerHTML={{ __html: data.page.contentHtml }}></div>
                    </div>
                    <div className="sample-ad uk-width-1-3@s uk-margin-medium-bottom">
                        <Image imgsrc="tf-phone-ad.png" alt="commissions icon" />
                    </div>
                </div>
            </div>

            <div id="bullets" className="uk-container uk-grid align-center uk-margin-large-top uk-margin-medium-bottom">
                {
                    data.page.how_it_works.map((item, index) => {
                        return <HowItWorks data={item} content={item.content2Html} />
                    })
                }
            </div>

            <div className="banner_header lighter">
                <div className="uk-container">
                    <h3>Earn Money Now</h3>
                    <div>
                        <div onClick={toggle} className="uk-button uk-button-primary" uk-toggle="#signup-modal">Get Started</div>
                    </div>
                </div>
            </div>
        </PagesLayout>
    )
}

export default LearnMore

export const pageQuery = graphql`
query {
    site {
    siteMetadata {
        title
        }
    }
    page: dataJson(path: {eq: "/learn-more"}) {
        contentHtml
        how_it_works {
          title
          icon
          content2Html
        }
        rawJson
        fileRelativePath
    }
}
`